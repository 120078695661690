import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <p>{`Since a long time, I have been interested in building things, creating order, tackling new challenges, solving problems, optimizing processes and helping others to succeed, by affording them the tools and insights to do so. `}</p>
    <h3>{`A wide surface of accumulated knowledge`}</h3>
    <p>{`I studied areas within computer science, mathematics, statistics, economics, psychology, sociology and philosophy in both formal and informal settings, while gaining practical experience within the discipline of software engineering with topics such as data modeling, software architecture, software testing, software configuration management, (user) interface design including UX research and agile project management. More recently, I also acquired a high-level understanding of topics within data science and cryptography.`}</p>
    <h3>{`Experienced with modern technologies`}</h3>
    <p>{`During my gap year, I worked as a freelance full stack developer and after I graduated, I worked as a frontend engineer at a local startup, from which I acquired advanced knowledge on technologies such as Typescript, React, Redux, MobX, RxJS, Apollo GraphQL, Jest, Cypress, Docker, Git etc. Over the years, I have also become familiar with Node.js, Nest.js, TypeORM, Redis, NATS and other backend related technologies, while using it for projects where I worked on in my spare time.`}</p>
    <h3>{`A big picture thinker`}</h3>
    <p>{`As a mostly INTP personality, one thing that I feel makes me quite a unique indiviual, is that I have the innate ability to objectively make decisions, by analytically approaching a problem from multiple perspectives and weighing every potential option through each of them.`}</p>
    <p>{`While this approach to decision-making sometimes leads to analysis paralysis, I learned to escape this phenomenon by actively trying out potential options (balancing convergence with divergence), while acknowledging the act as a gathering of insights, to justify the time spent (inspired by iterative methodology).  Even though this divergent approach never came comfortable to me, I learned that it was the only way to succeed.`}</p>
    <p>{`I also have the innate ability to intuitively understand individuals, their intentions and find the root causes of issues in both organizations and complex systems by looking for patterns in observed phenomena which others perceive to be completely unrelated.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      